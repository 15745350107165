import { Box, Button } from "@mui/material";
import { StampGiftDetailViewType } from "../app/StampGiftDetail";

const StampGiftDetailView: StampGiftDetailViewType = ({ item, onClickExchange, isLoading, exchanged, exchangeDisabled }) => {
    if (exchanged) {
        return (
            <Box sx={{
                p: 1,
            }}>
                <Box sx={{ p: 1, fontSize: "2em" }}>{isLoading ? '' : item.name}</Box>
                <Box sx={{ p: 1, fontSize: "1.5em", visibility: isLoading ? 'hidden' : 'visible' }}>{item.point}pt</Box>
                <Box sx={{ p: 1, display: 'flex', flexDirection: 'row', justifyContent: 'stretch' }}>
                    <img src={require('./images/gift_exchange.png')} alt="" />
                </Box>
                <Box sx={{ p: 1, fontSize: "1.2em" }} component="p">
                    ポイントを交換しました！ <br />
                    こちらの画面をスタッフに提示してください。
                </Box>
            </Box>
        )
    }
    return (
        <Box sx={{
            p: 1,
        }}>
            <Box sx={{ p: 1, fontSize: "2em" }}>{isLoading ? '' : item.name}</Box>
            <Box sx={{ p: 1, fontSize: "1.5em", visibility: isLoading ? 'hidden' : 'visible' }}>{item.point}pt</Box>
            <Box sx={{ p: 1, display: 'flex', flexDirection: 'row', justifyContent: 'stretch' }}>
                <img src={require('./images/gift_detail.png')} alt="" />
            </Box>
            <Box sx={{ p: 1 }} component="p">
                「交換する」を押すと、ポイントが消費され、画面が切り替わります。<br />
                次の画面をスタッフに提示してください。 <br />
            </Box>
            <Box sx={{ p: 1, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <Button onClick={() => onClickExchange()} variant="contained" disabled={isLoading} size="large">交換する</Button>
            </Box>
        </Box>
    )
}

export default StampGiftDetailView
